import React from 'react'
import styled from 'styled-components'
import { H1, P } from '../components/Typography'
import Layout from '../components/layout'
import SEO from '../components/seo'
import ContentWrapper from '../components/ContentWrapper'
import InternalLink from '../components/InternalLink'

const Wrapper = styled.div`
  margin-top: 40px;
`

const NotFoundPage = () => (
  <Layout title="404: Not found">
    <ContentWrapper>
      <Wrapper>
        <SEO title="404: Not found" />
        <H1>404: Not Found</H1>
        <P>You just hit a page doesn&#39;t exist</P>
        <P>
          <InternalLink to="/">
            Return to Design Patterns for Mental Health home page
          </InternalLink>
        </P>
      </Wrapper>
    </ContentWrapper>
  </Layout>
)

export default NotFoundPage
